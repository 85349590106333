<template>
  <b-modal
      :id="modal.id"
      hide-header
      hide-footer
      size="lg"
      centered
      @hidden="onHidden"
      @show="onShow"
  >
    <div class="header">
      <div class="header-content">
        <p>Filtro de Orçamentos</p>
        <Close class="close-icon" @click="closeModal" />
      </div>
    </div>
    <div class="body">
      <div class="body-content">
        <b-row class="mx-3 mt-2">
          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Por Data de</label>
              <PeriodDate v-model="filters" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mx-3">
          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Tipo de Solicitação</label>
              <BudgetRequestTypesSelect v-model="filters" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Convênio</label>
              <HealthPlanSelect v-model="filters" :clinic_id="clinic_id" />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- procedure item select -->
        <b-row v-if="filters.health_plan_id" class="mx-3">
          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Procedimento/Item</label>
              <SimpleItemSelect v-model="filters" :clinic_id="clinic_id" @onItemSelect="itemSelect" />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- request type & requesting doctor-->
        <b-row class="mx-3">
          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Situação</label>
              <BudgetStatusSelect v-model="filters" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="form-group">
              <label for="item-select">Médico Solicitante</label>
              <SimpleDoctorSelect v-model="filters" :clinic_id="clinic_id" />
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>

    <div class="footer">
      <div class="footer-content">
        <b-button variant="link" @click="resetFields">
          Redefinir
        </b-button>
        <b-button variant="primary" @click="applyFilter">
          Filtrar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {budgetFilters} from "@sp/config/selling-panel"
export default {
  props: {
    value: {
      type: Object,
      default: {}
    },
    clinic_id: {
      type: String,
      default: null
    },
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    PeriodDate: () => import("@sp/components/AdvancedSearch/PeriodDate"),
    BudgetRequestTypesSelect: () => import("@sp/components/BudgetRequestTypesSelect"),
    BudgetStatusSelect: () => import("@sp/components/AdvancedSearch/BudgetStatusSelect"),
    SimpleDoctorSelect: () => import("@sp/components/SimpleDoctorSelect.vue"),
    HealthPlanSelect: () => import("@sp/components/AdvancedSearch/HealthPlanSelect.vue"),
    SimpleItemSelect: () => import("@sp/components/Items/SimpleItemSelect.vue"),
  },
  data() {
    return {
      filters: JSON.parse(JSON.stringify(budgetFilters)),
      modal: {
        id: 'budget_advanced_search'
      }
    }
  },
  methods: {

    async onShow() {
    },
    closeModal() {
      this.$bvModal.hide(this.modal.id)
    },
    onHidden() {
      this.sent = false
    },

    resetFields() {
      this.filters = JSON.parse(JSON.stringify(budgetFilters))
      console.log(this.filters, Object.assign({}, budgetFilters))
      Object.keys(this.filters).map(filter => {
         this.value[filter] = this.filters[filter]
      })
      this.$emit('onAdvancedFilter', {activated: false})
      this.closeModal()
    },

    itemSelect(item) {
      this.filters.item_id = item.id
    },
    applyFilter() {

      if(this.filters.request_types.length) {
        this.value.request_types = this.filters.request_types.map(type => type.id)
      }

      if(this.filters.status.length) {
        this.value.status = this.filters.status.map(status => status.value)
      }

      this.value.health_plan_id = this.filters.health_plan_id?.id
      this.value.requester_doctor_id = this.filters.requester_doctor_id?.id
      this.value.period = this.filters.period

      this.$emit('onAdvancedFilter', {activated: true})
      this.closeModal()
    }
  }
}
</script>
<style lang="scss">
#budget_advanced_search {
  border-radius: 8px;
  .modal-content {
    .modal-body {
      padding: 0;
      .header {
        border-bottom: 1px solid var(--neutral-200);
        .header-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px 24px;
          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 28px;
          }
          .close-icon {
            fill: var(--neutral-500);
            height: 24px;
            width: 24px;
          }
        }
      }

      .body {
        display: flex;
        flex-direction: column;

        .body-content {
          display: flex;
          flex-direction: column;

          .container {
            display: flex;
            flex-direction: column;
            padding: 24px;

            .title-body-content {
              color: var(--dark-blue);
              font-weight: 600;
              font-size: 16px;
              line-height: 150%;
            }
          }
        }
      }

      .footer {
        .footer-content {
          display: flex;
          justify-content: right;
          margin: 24px;

          button {
            width: auto;
            height: auto;
            padding: 14px 16px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            border-radius: 8px;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>
<script setup>
</script>